<template>
  <div class="about">
    <div>
      <el-input style="width: 300px;margin: 0 10px 0 0"  v-model="keywords"></el-input>
      <el-button @click="search()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
    </div>
    <el-table  height="calc(100vh - 255px)" :data="tableData" style="width: 100%;">
<!--      <el-table-column type="expand" label="#" >-->
<!--        <template #default="scope">-->
<!--          <el-scrollbar max-height="300px">-->
<!--            <div style="padding: 20px" v-html="scope.row.content">-->
<!--            </div>-->
<!--          </el-scrollbar>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column prop="pic_url"  width="80" label="图片" >
        <template #default="scope">
          <div style="height: 100%;display: flex;align-items:center;">
            <el-image
                v-if="scope.row.pic_url"
                style="width: 50px; height: 50px"
                :src="scope.row.pic_url"
                :preview-src-list="[scope.row.pic_url]">
            </el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip prop="title" label="标题" >
      </el-table-column>
      <el-table-column width="110" prop="category_name" label="文章分类" >
      </el-table-column>
      <el-table-column
          prop="text"
          width="70"
          label="平台">
        <template #default="scope">
          <span v-if="scope.row.platform==0">客户端</span>
          <span v-else>师傅端</span>
        </template>
      </el-table-column>
      <el-table-column width="160" prop="create_time" label="时间" >
      </el-table-column>
      <el-table-column prop="address" label="操作" width="125">
        <template #header>
          <el-button  @click="add()" type="primary" size="mini"  style="margin:10px 0;">新增</el-button>
        </template>
        <template #default="scope">
          <div style="margin: 5px 0">
            <el-button-group>
              <el-button size="mini" type="primary" icon="el-icon-edit" @click="upData(scope.row)"></el-button>
              <el-button size="mini" type="primary" icon="el-icon-delete" @click="deleData(scope.row)"></el-button>
            </el-button-group>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 20px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 30, 40,50]"
          :page-size="limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
      >
      </el-pagination>
    </div>
    <el-drawer
        title="新增/修改"
        v-model="drawer"
        size="50%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <el-scrollbar height="calc(100vh - 160px)" style="padding-right: 15px;height:calc(100% - 30px)">
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="90px"
          class="demo-ruleForm">
        <div class="tabForm">
          <el-form-item label="标题" prop="title">
            <el-input v-model="ruleForm.title"></el-input>
          </el-form-item>
          <el-form-item label="文章分类" prop="category_id">
            <el-cascader
                placeholder=""
                ref="cascaderHandle"
                style="width: 100%"
                :options="categoryArr"
                v-model="ruleForm.category_id"
                :props="{
                            label: 'name',
                            value: 'id',
                            children: 'children',
                            checkStrictly: true
                            }"
                :filterable="true">
            </el-cascader>
          </el-form-item>

        <el-form-item label="阅读量" prop="">
          <el-input-number
              style="width: 100%"
              v-model="ruleForm.read_count"
              :min="0"
              controls-position="right"
          />
        </el-form-item>
        <el-form-item label="点赞次数" prop="">
          <el-input-number
              style="width: 100%"
              v-model="ruleForm.praise_count"
              :min="0"
              controls-position="right"
          />
        </el-form-item>
        </div>
        <div class="tabForm">
          <el-form-item  label="图片" prop="pic_url">
            <el-upload
                style=" width: 100px;height: 100px"
                class="avatar-uploader"
                :action="UpURL"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :http-request="httpRequest"
                :before-upload="beforeAvatarUpload"
            >
              <img v-if="ruleForm.pic_url" :src="ruleForm.pic_url" class="avatar" />
              <i v-else  class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <p style="color: #999999"><span style="font-size: 16px" class="el-icon-info"> </span>图片尺寸 宽 672 X 高 248</p>

          </el-form-item>
        </div>
        <div class="tab">
          <div style="color: #606266;display: flex;justify-content: space-between"><span>● 文章标签</span><el-button size="medium" type="text" @click="addProduct()"> 添加文章标签</el-button></div>
          <el-table  max-height="300px" :data="ruleForm.tags" style="width: 100%;margin:10px 0 30px 0">
            <el-table-column  type="index" label="#" width="60">
            </el-table-column>
            <el-table-column prop="name" label="商品名称" min-width="110">
            </el-table-column>
            <el-table-column fixed="right" prop="unit" label="操作" width="57">
              <template #default="scope">
                <el-button size="medium" type="text" @click="deletCou(scope.row,scope.$index)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-form-item  label="文章内容" label-position="top"  prop="content">
        </el-form-item>
        <div style="width: calc(100%);margin-top: -20px">
          <ueItem :content="ruleForm.content" :name="'article'" @getContent="getContent"></ueItem>
        </div>
       </el-form>
      </el-scrollbar>
      <el-button type="primary" v-if="ruleForm.id" :loading="login" @click="okAdd('ruleForm',2)">修改</el-button>
      <el-button type="primary" v-else :loading="login" @click="okAdd('ruleForm',1)">保存</el-button>
      <el-drawer
          title="新增文章标签"
          size="30%"
          :append-to-body="true"
          v-model="LabeDrawerCou" >
        <el-scrollbar style="height: 90%" height="600px"  >
          <div>
            <el-input size="mini" style="width: 300px;margin: 0 10px 0 0"  v-model="Coukeywords"></el-input>
            <el-button size="mini" @click="Cousearch()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
          </div>
          <el-table @select="handChange"  max-height="450px" :data="CoukListarr" style="width: 100%;margin:10px 0 30px 0">
            <el-table-column
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column prop="name" label="文章标签" min-width="120">
            </el-table-column>
          </el-table>
          <el-pagination
              small
              background
              :pager-count="5"
              @size-change="CouSizeChange"
              @current-change="CouCurrentChange"
              :current-page="Coupage"
              :page-sizes="[10, 20, 30, 40,50]"
              :page-size="Coulimit"
              layout="total, sizes, prev, pager, next, jumper"
              :total="Coutotal">
          </el-pagination>
        </el-scrollbar>
        <el-button type="primary" @click="okaddCou()">确定</el-button>
      </el-drawer>
    </el-drawer>
  </div>
</template>
<script>
// @ is an alias to /src
import {getArea, getIMG, getUQIMGurl, postProduct} from "@/api/common";
import * as axios from "@/api/articleApi"//本页面接口
import ueItem from './ue/ueItem'
// import { ref } from 'vue';
export default {
  name: 'Specs',
  components:{
    ueItem,
  },
  data(){
    return{
      editorConfig:'',//富文本
      /*商品--*/
      CoukListarr:[],//城市组
      CoukList:[],//城市
      nowCoukList:[],//选中城市
      LabeDrawerCou:false,//标签
      Coupage:1,
      Coulimit:10,
      Coutotal:0,
      Coukeywords:'',
      /*商品--*/
      UpURL:'',
      login:false,
      drawer:false,
      categoryArr:[],
      skurowData:[],
      tableData:[],
      areaArr:[],//城市
      page:1,
      limit:10,
      total:0,
      keywords:'',
      brand:[
        {
          id:0,
          name:'客户端',
        },
        {
          id:1,
          name:'师傅端',
        },
      ],
      space:[],
      ruleForm:{
        title:'',
        category_id:'',
        pic_url:'',
        read_count:0,
        praise_count:0,
        content:'',
        tags:[],
      },
      rules:{
        title: [
          { required: true, message: '请填写标题', trigger: 'blue' },
        ],
        category_id: [
          { required: true, message: '请选择', trigger: 'blue' },
        ],
        pic_url: [
          { required: true, message: '请上传', trigger: 'blue' },
        ],
        content: [
          { required: true, message: '请上传', trigger: 'blue' },
        ],
      },
    }
  },
  created() {
    this.editorConfig = {
      autoHeightEnabled: false,
      initialFrameHeight: 600,
      // 初始容器宽度
      initialFrameWidth: '100%',
      UEDITOR_HOME_URL: '/UEditor/', // 访问 UEditor 静态资源的根路径，可参考常见问题1
      // serverUrl: '//ueditor.szcloudplus.com/cos', // 服务端接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
    };
    this.onTable()
    this.onArea()
    this.oncategory()
    // this.keyupSubmit()
  },
  methods:{
    getContent(html){
      this.ruleForm.content=html
      // console.log('父组件接受',html)
    },
    /*指定商品*/
    Cousearch(){
      this.Coupage=1
      this.onProductdata()
    },
    onProductdata(){
      let data={
        page:this.Coupage,
        limit:this.Coulimit,
        keywords:this.Coukeywords,
      }
      axios.getarticleTagData(data).then((res)=>{
        if(res.code==200){
          this.CoukListarr=res.data.data
          this.Coutotal=res.data.total

        }
      }).catch(()=>{

      })
    },
    addProduct(){
      this.onProductdata()
      this.LabeDrawerCou=true
    },
    CouSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.Coulimit=val
      this.onProductdata()
    },
    CouCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.Coupage=val
      this.onProductdata()
    },
    handChange(selection,row){
      var newNum1 = this.ruleForm.tags.find((item, index) => {
        return item.tag_id == row.id;
      })
      if(newNum1){
        console.log(newNum1)
      }else {
        this.ruleForm.tags.push({tag_id:row.id,name:row.name})
      }
    },
    okaddCou(){
      this.LabeDrawerCou=false
    },
    deletCou(row,i){
      if(row.id){
        this.$confirm('删除!!, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          axios.postdeladelTags({id:row.id}).then((res)=>{
            if(res.code==200){
              this.$message.success(res.msg)
              this.ruleForm.tags.splice(i,1)
            }else {
              this.$message.error(res.msg)
            }
          }).catch((err)=>{
            this.$message.error('服务器报错！！请联系管理员')
          })
        })
            .catch(() => {
              this.$message({
                type: 'error',
                message: '已取消删除',
              })
            })
      }else {
        this.ruleForm.tags.splice(i,1)
      }
    },
    /*-----------*/
    oncategory(){
      axios.getCategoryData().then((res)=>{
        this.categoryArr=res.data
      }).catch((err)=>{

      })
    },
    /*图片上传*/
    handleAvatarSuccess(res, file) {
      this.logo = URL.createObjectURL(file.raw)
    },
    //LOGO上传图片方法
    httpRequest(options) {
      this.login=true
      // console.log('options',options.file,options.file.type)
      return new Promise((resolve, reject) => {
        let src=options.file.type
        getUQIMGurl({bucket:'product',ext:'.'+src.substr(6)}).then((res)=>{
          // console.log('获取URL',res)
          if(res.code==0){
            this.UpURL=res.data.upload_url
            let url=res.data.preview_url
            // console.log(this.UpURL)
            getIMG(options.file,this.UpURL,options.file.type).then((res)=>{
              resolve(res)
              this.ruleForm.pic_url=url
              this.login=false
            }).catch((err)=>{
              reject(err)
              this.login=false
            })

          }else {
            this.$message.error('获取上传地址失败！！')
            this.login=false
          }
        })

      })
    },
    //
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'||
          file.type === "image/png" ||
          file.type === "image/jpg"||
          file.type === "image/gif";
      // const isLt2M = file.size / 1024 / 1024 < 4
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG、JPEG、PNG、 格式!')
      }
      // if (!isLt2M) {
      // this.$message.error('上传头像图片大小不能超过 4MB!')
      // }
      return isJPG
      // && isLt2M
    },
    /*图片上传*/
    onArea(){
      getArea().then((res)=>{
        this.areaArr=res.data
      }).catch((err)=>{

      })
    },
    onTable(){
      let form={
        page:this.page,
        limit:this.limit,
        keywords:this.keywords,
      }
      axios.getarticleData(form).then((res)=>{
        console.log(res)
        this.tableData=res.data.data
        console.log('this.tableData',this.tableData)
        this.total=res.data.total
      }).catch((err)=>{

      })
    },
    search(){
      this.page=1
      this.onTable()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.limit=val
      this.onTable()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.page=val
      this.onTable()
    },

    add(){

      this.ruleForm={
        title:'',
        category_id:'',
        pic_url:'',
        read_count:0,
        praise_count:0,
        content:'',
        tags:[],
      }
      this.drawer=true
    },
    upData(row){
      console.log(row)
      this.ruleForm.id=row.id
      this.ruleForm.title=row.title
      this.ruleForm.category_id=row.category_id
      this.ruleForm.pic_url=row.pic_url
      this.ruleForm.read_count=row.read_count
      this.ruleForm.praise_count=row.praise_count
      this.ruleForm.content=row.content
      this.ruleForm.tags=row.tags
      // this.ruleForm.tags=JSON.parse(row.tags)
      this.drawer=true
    },
    okAdd(formName,val){
      if(this.login){
        return false
      }
      this.login=true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(val==1){
            axios.postaddarticle(this.ruleForm).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                  that.login=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }else {
            axios.posteditarticle(this.ruleForm).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                this.login=false
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }
        } else {
          if(this.ruleForm.content){

          }else {
            this.$message.error('请填写文章内容！！')
          }
          this.login=false
          console.log('error submit!!')
          return false
        }
      })
    },
    deleData(row){
      this.$confirm('删除!!, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            axios.postdelarticle({id:row.id}).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.$message.error('服务器报错！！请联系管理员')
            })
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '已取消删除',
            })
          })
    },
  }
}
</script>
<style lang="less" >
.tabForm{
  margin: 5px 0 ;
  padding: 10px 20px 0 5px;
  border: 1px solid #e8e8e8;
}
.tab{
  margin: 5px 0 ;
  padding: 10px 20px;
  border: 1px solid #e8e8e8;
}
.avatar-uploader .el-upload {
  border: 1px dashed #c1c1c1;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.el-upload{

}
.el-checkbox{
  height: 32px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 98px;
  height: 98px;
  line-height: 98px;
  text-align: center;
}
.avatar {
  width: 98px;
  height: 98px;
  display: block;
}
</style>